<template>
  <div class="grid">
    <div class="col-12 md:col-12">
      <div class="card">
        <DataTable ref="dtManifests" :value="packageItems" :showGridlines="true" editMode="row" dataKey="id"
          v-model:editingRows="editingRows" @row-edit-save="onRowEditSave" responsiveLayout="scroll">
          <template #header>
            <div class="table-header">
              <div class="block mt-2 md:mt-0">
                <Button icon="pi pi-plus" :label="$t('button.add')" @click="onCreateItem" />
                <i class="mr-2" />
                <!-- <Button
                  icon="pi pi-save"
                  :label="$t('button.save')"
                  @click="onSaveItems"
                /> -->
              </div>
            </div>
          </template>
          <Column field="Index" header="#" style="width: 5%">
            <template #body="{ index }">
              {{ index + 1 }}
            </template>
          </Column>
          <Column style="width: 8rem; min-width: 8rem">
            <template #body="slotProps">
              <Button icon="pi pi-pencil" outlined rounded class="mr-2" v-if="!isEdittingRow(slotProps.data)"
                @click="onEditItem(slotProps.data, slotProps.index)" />
              <Button icon="pi pi-trash" outlined rounded severity="danger" v-if="!isEdittingRow(slotProps.data)"
                @click="onDeleteItem(slotProps.data)" />
              <Button icon="pi pi-check" outlined rounded class="mr-2" v-if="isEdittingRow(slotProps.data)"
                @click="onSaveItem(slotProps.data)" />
              <Button icon="pi pi-times" outlined rounded class="mr-2" v-if="isEdittingRow(slotProps.data)"
                @click="onCancelEditItem(slotProps.data, slotProps.index)" />
            </template>
          </Column>
          <Column field="name" :header="$t('packageItem.name')">
            <template #editor="{ data }">
              <!-- <InputText v-model="data[field]" style="width: 90%" /> -->

              <AutoComplete style="width: 90%" v-model="data.item" field="name" optionLabel="name" dataKey="name"
                :dropdown="true" :suggestions="data.filteredManifestItems" @change="onChangeManifestItem($event, data)"
                @item-select="onSelectManifestItem($event, data)" @complete="onFiterManifestItem($event, data)" />
              <!--
              <AutoCompleteValue
                style="width: 90%"
                v-model="data.name"
                :options="manifestItems"
                :required="true"
                placeholder=""
                @item-select="onChangeManifestItem($event)"
              />
							-->
            </template>
          </Column>
          <!-- <Column field="price" :header="$t('packageItem.price')">
            <template #editor="{ data, field }">
              <label>{{ data[field] }}</label>
            </template>
          </Column> -->
          <Column field="quantity" :header="$t('packageItem.quantity')" style="width: 10%">
            <template #editor="{ data, field }">
              <InputNumber v-model="data[field]" :inputStyle="{ width: '80px' }" />
            </template>
          </Column>
          <!-- <Column field="tax" :header="$t('packageItem.tax')" style="width: 10%">
            <template #editor="{ data, field }">
              <InputNumber v-model="data[field]" :inputStyle="{ width: '80px' }" mode="currency" currency="USD"
                @blur="(event) => onChangeItemTax(event, data, field)"
                @input="(event) => handleTaxInput(event, data, field)" />
            </template>
          </Column> -->
          <!--
						<Column field="packageCode" :header="$t('packageItem.packageCode')" style="width: 10%">
								<template #editor="{ data, field }">
									<Dropdown v-model="data[field]"
										:options="receipt.packageDtos"
										optionLabel="packageCode"
										optionValue="packageCode"
										:placeholder="$t('packageItem.select_package')">
											<template #option="slotProps">
													<span>{{slotProps.option.packageCode}}</span>
											</template>
									</Dropdown>
								</template>
						</Column>
						-->
          <Column field="type" :header="$t('packageItem.type')" style="width: 10%">
            <template #editor="{ data, field }">
              <Dropdown v-model="data[field]" :options="typeOptions" optionLabel="label" optionValue="value" placeholder="Select a Type">
                <template #option="slotProps">
                  <span>{{slotProps.option.label}}</span>
                </template>
              </Dropdown>
            </template>
            <template #body="{ data, field }">
              <label>{{ data[field] }}</label>
            </template>
          </Column>
          <!-- <Column field="extraFee" :header="$t('packageItem.extraFee')" style="width: 10%">
            <template #editor="{ data, field }">
              <InputNumber v-model="data[field]" :inputStyle="{ width: '80px' }" mode="currency" currency="USD"
                @blur="(event) => onChangeExtraFee(event, data, field)"
                @input="(event) => handleExtraFeeInput(event, data, field) " />
            </template>
          </Column> -->
          <Column field="description" :header="$t('packageItem.description')" style="width: 20%">
            <template #editor="{ data, field }">
              <InputText v-model="data[field]" style="width: 90%" />
            </template>
          </Column>
          <!--
						<Column field="inventoryStatus" header="Status" style="width:20%">
								<template #editor="{ data, field }">
										<Dropdown v-model="data[field]" :options="statuses" optionLabel="label" optionValue="value" placeholder="Select a Status">
												<template #option="slotProps">
														<span :class="'product-badge status-' + slotProps.option.value.toLowerCase()">{{slotProps.option.label}}</span>
												</template>
										</Dropdown>
								</template>

						</Column>
						<Column field="price" header="Price" style="width:20%">
								<template #editor="{ data, field }">
										<InputText v-model="data[field]" />
								</template>
						</Column>
						-->
          <!-- <Column
            :rowEditor="true"
            style="width: 10%; min-width: 8rem"
            bodyStyle="text-align:center"
          ></Column> -->
        </DataTable>
      </div>
      <!--
			<TabView>
				<TabPanel :header="$t('package.manifests')">
					<CrudTable :entities="packageItems"
						:meta="metaPackageItem"
						@tableAction="onTableAction"
						@itemAction="onItemAction"/>
				</TabPanel>
				<TabPanel :header="$t('package.images')">
					<Galleria :value="images" :responsiveOptions="responsiveOptions" :numVisible="5" :circular="true" containerStyle="max-width: 640px"
						:showItemNavigators="true" :showThumbnails="false">
						<template #item="slotProps">
								<img :src="slotProps.item.itemImageSrc" :alt="slotProps.item.alt" style="width: 100%; display: block;" />
						</template>

						<template #thumbnail="slotProps">
								<img :src="slotProps.item.thumbnailImageSrc" :alt="slotProps.item.alt" style="display: block;" />
						</template>
					</Galleria>
				</TabPanel>
			</TabView>
			-->
    </div>
  </div>
</template>

<script>
import { FilterMatchMode } from "primevue/api";
import ImageService from "@/service/ImageService";
import ManifestItemService from "@/service/ManifestItemService";
import PackageItemService from "@/service/PackageItemService";
//import AutoCompleteValue from "@/components/form/AutoCompleteValue";
// import ReceiptService from '@/service/ReceiptService'
//import PackageService from '@/service/PackageService'
//import CrudTable from '@/pages/common/CrudTable'
//import Utils from '@/service/Utils'
const EDITING_ROWS = 1;
export default {
  //components: { AutoCompleteValue },
  emits: ["changeTax", "changeExtraFee", "saveSuccess", "deleteSuccess"],
  props: {
    receipt: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      packageEntity: {},
      selectedPackage: null,
      images: [],
      displayCustom: true,
      typeOptions: [
        { label: this.$t("package.goodsTypes.normal"), value: "normal" },
        { label: this.$t("package.goodsTypes.special"), value: "special" },
      ],
      responsiveOptions: [
        {
          breakpoint: "1024px",
          numVisible: 5,
        },
        {
          breakpoint: "768px",
          numVisible: 3,
        },
        {
          breakpoint: "560px",
          numVisible: 1,
        },
      ],
      packageItems: [],
      manifestItems: [],
      editingItem: null,
      editingRows: [],
      metaPackageItem: {
        entityName: "packageItem",
        selectionMode: "multiple",
        paginator: {
          enable: false,
        },
        columns: [
          {
            name: "name",
            headerStyle: "width:20%; min-width:10rem;",
          },
          {
            name: "electronic",
            headerStyle: "width:20%; min-width:10rem;",
          },
          {
            name: "quantity",
            headerStyle: "width:20%; min-width:10rem;",
          },
        ],
        tableActions: [
          /*
						{
							name: "create",
							icon: "pi pi-plus",
							style: "p-button-rounded p-button-success mr-2"
						}
						*/
        ],
        itemActions: [
          {
            name: "edit",
            icon: "pi pi-pencil",
            style: "p-button-rounded p-button-success mr-2",
            condition: "mode != 'edit'",
          },
          {
            name: "save",
            icon: "pi pi-save",
            style: "p-button-rounded p-button-success mr-2",
            condition: "mode == 'edit'",
          },
          {
            name: "delete",
            icon: "pi pi-trash",
            style: "p-button-rounded p-button-warning mt-2",
            condition: "mode != 'edit'",
          },
        ],
      },
    };
  },
  mounted() {
    this.loadManifestItems();
    this.$watch(
      () => this.$props.receipt,
      (receipt) => {
        this.packageItems = receipt.packageItemDtos || [];
        this._setManifestItems();
        // let editorLength = EDITING_ROWS;
        // if (this.packageItems.length > EDITING_ROWS) {
        //   editorLength = this.packageItems.length;
        // }
        this._initEditingItems();
      }
    );
  },
  updated() {
    // console.log("Updated ReceiptManifest");
    /*
			if(this.$route.params.receiptId) {
				ReceiptService.get(this.$route.params.receiptId).then(data => {
					this.receipt = data;
					if(this.$route.params.packageId) {
						for(var i = 0; i < data.packageEntities.length; i++) {
							if (data.packageEntities[i].id == this.$route.params.packageId) {
								this.selectedPackage = data.packageEntities[i];
								this.loadImages(this.selectedPackage);
								this.loadPackageItems(this.selectedPackage);
								break;
							}
						}
					}
				});
			}
			*/
    // this.packageItems = this.receipt.packageItems || [];
    // let editorLength = 20;
    // if (this.packageItems.length > 20) {
    //   editorLength = this.packageItems.length;
    // }
    // this._initEditingItems(editorLength);
  },
  methods: {
    _initEditingItems(counter) {
      counter = counter || EDITING_ROWS;
      let editingRows = [];
      this.packageItems.forEach((item, ind) => {
        item.index = counter + ind;
        //editingRows.push(item);
      });
      for (let i = 0; i < counter; i++) {
        let template = {
          editting: true,
          index: i,
          receiptId: this.receipt.id,
          receiptCode: this.receipt.receiptCode,
          tax: 0,
          name: null,
          electronic: false,
          quantity: 1,
        };
        this.packageItems.unshift(template);
        editingRows.push(template);
      }
      this.editingRows = editingRows;
    },
    isEdittingRow(row) {
      //console.log(row);
      return row?.editting;
      //return this.editingRows.indexOf(row) >= 0;
    },
    onCreateItem() {
      this._initEditingItems(EDITING_ROWS);
    },
    onDeleteItem(rowData) {
      if (rowData.id) {
        PackageItemService.delete(rowData.id).then((res) => {
          this.$emit("deleteSuccess", res);
        });
      }
    },
    onEditItem(rowData) {
      rowData.editting = true;
      rowData.original = { ...rowData };
      this.editingRows = [rowData, ...this.editingRows];
    },
    onSaveItem(rowData) {
      rowData.editting = false;
      const name = rowData.name || rowData.item;
      let duplicatedItems = this.packageItems.filter(
        (item) => item.name == name && item.index != rowData.index
      );
      if (duplicatedItems.length > 0) {
        this.$toast.add({
          position: "top-center",
          severity: "warn",
          summary: "Warn",
          detail: this.$t("receipt.item_already_selected", { name }),
          life: 3000,
        });
      } else {
        let oldItems = this.manifestItems.filter((item) => item.name == name);
        if (oldItems.length == 0) {
          this._saveNewManifestItems([name]);
        }
        rowData.name = name;
        if (rowData.id) {
          PackageItemService.update(rowData.id, rowData).then((res) => {
            this.$emit("saveSuccess", res);
          });
        } else {
          PackageItemService.create(rowData).then((res) => {
            this.$emit("saveSuccess", res);
          });
        }
      }
    },
    onCancelEditItem(rowData, index) {
      let editingRows = [];
      for (let i = 0; i < this.editingRows.length; i++) {
        if (this.editingRows[i].index != index) {
          editingRows.push(this.editingRows[i]);
        } else {
          this.editingRows[i].editting = false;
        }
      }
      this.editingRows = editingRows;
      for (let field in rowData.original) {
        rowData[field] = rowData.original[field];
      }
      //rowData.editting = false;
      delete rowData.original;
      //rowData.original = null;
    },
    onChangeItemTax(event, data, field) {
      console.log(event, data, field);
      let totalTax = this.packageItems.reduce((total, item) => { return total + (item.tax || 0); }, 0);
      //totalTax = this.editingRows.reduce((total, item) => { return total + item.tax; }, totalTax);
      this.$emit("changeTax", totalTax);
    },
    handleTaxInput() {
      // let totalTax = this.packageItems.reduce((total, item) => { return total + item.tax; }, 0);
      // this.$emit("changeTax", totalTax);
    },
    // handleTaxInput(event, data, field) {
    //   let change = event.value;
    //   if (data[field] != undefined && data[field] != null) {
    //     change -= data[field]
    //   }
    //   this._aggregateTax();
    //   //this.$emit("changeTax", change);
    // },
    onChangeExtraFee(event, data, field) {
      console.log(event, data, field);
      let totalExtraFee = this.packageItems.reduce((total, item) => { return total + (item.extraFee || 0); }, 0);
      this.$emit("changeExtraFee", totalExtraFee);
    },
    handleExtraFeeInput() {
      // let totalTax = this.packageItems.reduce((total, item) => { return total + item.tax; }, 0);
      // this.$emit("changeTax", totalTax);
    },
    
    onSaveItems() {
      let editingMeta = this.$refs.dtManifests.d_editingMeta;
      let editedItems = [];
      let newItems = [];
      let hasElectronic = false;
      const pkgNum = this.receipt.packageDtos.length;
      for (let i = 0; i < this.packageItems.length; i++) {
        if (typeof this.packageItems[i].item == "string") {
          newItems.push(this.packageItems[i].item);
        }
        let item = {};
        if (editingMeta[i]) {
          this._setItemValue(item, this.packageItems[i], editingMeta[i].data);
          //item = { ...this.packageItems[i], ...editingMeta[i].data };
        } else {
          this._setItemValue(item, this.packageItems[i]);
          //item = this.packageItems[i];
        }
        if (item && item.name) {
          if (!item.packageCode && pkgNum > 0) {
            //Set random packageCode
            //let ind = Math.floor(Math.random() * pkgNum);
            //item.packageCode = this.receipt.packageDtos[ind].packageCode;
          }
          if (item.electronic) {
            hasElectronic = true;
          }
          editedItems.push(item);
        }
      }
      this._saveNewManifestItems(newItems);
      this._saveAllItems(editedItems, hasElectronic);
    },
    _saveNewManifestItems(newItems) {
      if (newItems.length > 0) {
        ManifestItemService.create(newItems).then((resp) => {
          resp.forEach((newItem) => {
            let index = -1;
            this.manifestItems.findIndex(
              (item) => item.name.localeCompare(newItem.name) > 0
            );
            if (index == 0) {
              this.manifestItems.unshift(newItem);
            } else if (index > 0) {
              this.manifestItems.splice(index - 1, 0, newItem);
            } else {
              this.manifestItems.push(newItem);
            }
          });
        });
      }
    },
    _saveAllItems(editedItems, hasElectronic) {
      if (editedItems.length > 0) {
        let totalTax = editedItems.reduce((total, item) => {
          return total + (item.tax || 0);
        }, 0);
        this.$emit("changeTax", totalTax);
        if (hasElectronic && !this.receipt.extraTax) {
          this.$confirm.require({
            message: this.$t("receipt.missing_extra_tax"),
            header: this.$t("common.confirmation"),
            icon: "pi pi-exclamation-triangle",
            accept: () => {
              this._doSave(editedItems);
            },
            reject: () => {
              //callback to execute when user rejects the action
            },
          });
        } else {
          this._doSave(editedItems);
        }
      }
    },
    _setItemValue(item, pkgItem, editingData) {
      if (typeof pkgItem.item == "string") {
        item.name = pkgItem.item;
      }
      [
        "id",
        "name",
        "quantity",
        "packageCode",
        "electronic",
        "description",
        "tax",
      ].forEach((field) => {
        let fieldValue = editingData ? editingData[field] : pkgItem[field];
        if (fieldValue) {
          item[field] = fieldValue;
        }
      });
    },
    _aggregateTax() {
      let totalTax = this.editingRows.reduce((total, item) => {
        return total + item.tax;
      }, 0);
      this.$emit("changeTax", totalTax);
    },
    _doSave(packageItemDtos) {
      PackageItemService.saveItems({
        id: this.receipt.id,
        receiptCode: this.receipt.receiptCode,
        packageItemDtos,
      }).then((res) => {
        //console.log(res);
        this.$emit("saveSuccess", res);
        //this.packageItems = res;
        this._setManifestItems();
        this._initEditingItems();
        //this.editingRows = [];
      });
    },
    onRowEditSave() {
      this.onSaveItems();
      // let { newData, index } = event;
      // if (typeof newData.item == 'string') {
      //   newData.name = newData.item;
      //   ManifestItemService.create([newData.item]).then(resp =>{
      //     if (Array.isArray(resp)) {
      //       let newItem = resp[0];
      //       let index = -1;
      //       this.manifestItems.findIndex(item => item.name.localeCompare(newItem.name) > 0);
      //       if (index == 0) {
      //         this.manifestItems.unshift(newItem);
      //       } else if (index > 0) {
      //         this.manifestItems.splice(index - 1, 0, newItem);
      //       } else {
      //         this.manifestItems.push(newItem);
      //       }
      //       newData.item = newItem;
      //       PackageItemService.save(newData).then((res) => {
      //         this.packageItems[index] = res;
      //       });
      //     }
      //   });
      // } else {
      //   PackageItemService.save(newData).then((res) => {
      //     this.packageItems[index] = res;
      //   });
      // }
    },
    loadImages(receiptPkg) {
      if (receiptPkg) {
        ImageService.loadImages(receiptPkg).then((response) => {
          //const baseUrl = Utils.getImageBase();
          if (Array.isArray(response)) {
            this.images = response.map((content) => ({
              itemImageSrc: `data:image/png;base64, ${content}`,
              thumbnailImageSrc: `data:image/png;base64, ${content}`,
            }));
          }
        });
      }
    },
    loadPackageItems(receiptPkg) {
      if (receiptPkg) {
        this.packageItems = receiptPkg.packageItems;
        this.packageItems.splice(0, 0, { mode: "edit" });
      }
    },
    onChangePackage(event) {
      console.log(event);
    },
    onTableAction(sAction) {
      if (sAction == "create") {
        var pkgItem = {
          mode: "edit",
        };
        this.packageItems.push(pkgItem);
      }
    },
    onItemAction(sAction, entity, index) {
      if (sAction == "save") {
        if (entity.id) {
          PackageItemService.update(entity).then((response) => {
            this.packageItems[index] = response.data;
          });
        } else {
          let data = {
            packageCode: this.selectedPackage.packageCode,
            packageId: this.selectedPackage.id,
            receiptCode: this.receipt.receiptCode,
            ...entity,
          };
          PackageItemService.create(data).then((response) => {
            this.packageItems[index] = { mode: "edit" };
            this.packageItems.push(response.data);
          });
        }
      } else if (sAction == "edit") {
        entity.mode = "edit";
      } else if (sAction == "delete") {
        PackageItemService.delete(entity.id).then(() => {
          this.packageItems.splice(index, 1);
        });
      }
    },
    initManifestFilters() {
      this.filters = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      };
    },
    async loadManifestItems() {
      let response = await ManifestItemService.loadAll();
      this.manifestItems = response.data;
      this._setManifestItems();
      /*
      if (Array.isArray(response)) {()
        this.images = response.map((content) => ({
          itemImageSrc: `data:image/png;base64, ${content}`,
          thumbnailImageSrc: `data:image/png;base64, ${content}`,
        }));
      }
			*/
    },
    _setManifestItems() {
      this.packageItems.forEach((pkgItem) => {
        if (pkgItem.name) {
          let filteredItems = this.manifestItems.filter(
            (item) => item.name == pkgItem.name
          );
          if (Array.isArray(filteredItems) && filteredItems.length > 0) {
            pkgItem.item = filteredItems[0];
          }
        }
      });
    },
    onChangeManifestItem(event, rowData) {
      console.log(event, rowData);
      //new typed item
      // if(typeof rowData.item == 'string') {
      //   let manifestItem = {
      //     name: rowData.item
      //   };
      //   ManifestItemService.create(manifestItem).then(resp =>{
      //     let index = -1;
      //     this.manifestItems.findIndex(item => item.name.localeCompare(resp.name) > 0);
      //     if (index == 0) {
      //       this.manifestItems.unshift(resp);
      //     } else if (index > 0) {
      //       this.manifestItems.splice(index - 1, 0, resp);
      //     } else {
      //       this.manifestItems.push(resp);
      //     }
      //   });
      // }
    },
    onSelectManifestItem(event, rowData) {
      rowData.price = event.value.minPrice;
      if (rowData.item) {
        rowData.name = rowData.item.name;
      }
      //console.log(rowData);
    },
    onFiterManifestItem(event, rowData) {
      let query = event.query;
      setTimeout(() => {
        if (!query.trim().length) {
          rowData.filteredManifestItems = [...this.manifestItems];
        } else {
          let filter = query.toLowerCase();
          rowData.filteredManifestItems = this.manifestItems.filter((value) => {
            return value.name && value.name.toLowerCase().includes(filter);
          });
          if (rowData.filteredManifestItems.length == 1) {
            rowData.price = rowData.filteredManifestItems[0].minPrice;
          }
        }
      }, 100);
    },
  },
};
</script>
